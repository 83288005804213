/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';

import { reducer as login } from './Login';
import { reducer as course } from './Course';
import { reducer as focus } from './Focus';
import { reducer as dialog } from './Dialog';
import { reducer as simulator } from './Simulator';
import { reducer as skill } from './Skill';
import { reducer as step } from './Step';
import { reducer as academy } from './Academy';
import { reducer as academyStructure } from './AcademyStructure';
import { RootState } from './types';

export default combineReducers<RootState>({
  course,
  focus,
  login,
  dialog,
  simulator,
  skill,
  step,
  academy,
  academyStructure,
});
