import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDialog, useHideDialog } from 'redux/Dialog/hooks';
import { CenteredDialog } from '../CenteredDialog/CenteredDialog';
import {
  StopTrainingDialogAlert,
  StopTrainingDialogButtonContainer,
  StopTrainingDialogCancelButton,
  StopTrainingDialogConfirmButton,
  StopTrainingDialogContent,
} from './StopTrainingDialog.style';

export const StopTrainingDialog = (): JSX.Element => {
  const { handleOnClose } = useHideDialog();
  const intl = useIntl();
  const { dialog } = useDialog();

  const handleConfirmLeave = useCallback(() => {
    dialog.confirmAction !== undefined && dialog.confirmAction();
    handleOnClose();
  }, [dialog, handleOnClose]);
  return (
    <CenteredDialog
      title={intl.formatMessage({ id: 'dialog.stop-training.title' })}
      closeDialog={handleOnClose}
    >
      <StopTrainingDialogContent>
        <StopTrainingDialogAlert>
          {intl.formatMessage({ id: 'dialog.stop-training.alert-text' })}
        </StopTrainingDialogAlert>
        <StopTrainingDialogButtonContainer>
          <StopTrainingDialogCancelButton
            buttonContent={intl.formatMessage({ id: 'dialog.stop-training.cancel-button' })}
            onClick={handleOnClose}
            ariaLabel="cancel-button"
          ></StopTrainingDialogCancelButton>
          <StopTrainingDialogConfirmButton
            buttonTheme="neutral"
            buttonLabel={intl.formatMessage({ id: 'dialog.stop-training.confirm-button' })}
            onClick={handleConfirmLeave}
            ariaLabel="confirm-button"
          ></StopTrainingDialogConfirmButton>
        </StopTrainingDialogButtonContainer>
      </StopTrainingDialogContent>
    </CenteredDialog>
  );
};
