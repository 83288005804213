import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Step } from './types';

export type StepState = Readonly<{
  steps: Record<string, Step>;
}>;

const initialState: StepState = { steps: {} };

const focusesSlice = createSlice({
  name: 'Steps',
  initialState,
  reducers: {
    addSteps: (state, action: PayloadAction<Array<Step>>) => {
      action.payload.forEach((step: Step) => {
        state.steps[step.uuid] = step;
      });
    },
    addStep: (state, action: PayloadAction<Step>) => {
      state.steps[action.payload.uuid] = action.payload;
    },
    removeStep: (state, uuid: PayloadAction<string>) => {
      if (uuid.payload in state.steps) {
        delete state.steps[uuid.payload];
      }
    },
  },
});

export const { addSteps, addStep, removeStep } = focusesSlice.actions;
export default focusesSlice.reducer;
