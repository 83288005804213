import { RootState } from 'redux/types';
import { Focus } from './types';

const filterFocusesBySkill = (focuses: Focus[], skill_uuid: string): Focus[] => {
  return focuses.filter((focus) => focus.skill_uuid === skill_uuid);
};

export const getFocuses = (store: RootState): Focus[] => {
  return store.focus.focuses;
};

export const getFocusesBySkill =
  (skill_uuid: string) =>
  (store: RootState): Focus[] => {
    return filterFocusesBySkill(store.focus.focuses, skill_uuid);
  };

export const getFocusByUuid =
  (focus_uuid: string) =>
  (store: RootState): Focus | undefined => {
    const focuses = store.focus.focuses;
    return focuses.find((focus) => focus.uuid === focus_uuid);
  };
