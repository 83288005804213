import { ReactNode } from 'react';
import * as React from 'react';
interface Props {
  children: ReactNode;
}

const Root: React.FunctionComponent<Props> = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);

export default Root;
