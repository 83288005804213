import React from 'react';
import Dialog from '../Dialog/Dialog';
import {
  CenteredDialogContainer,
  CenteredDialogTitle,
  CenteredDialogContent,
  CenteredDialogHeader,
  CenteredDialogCloseButton,
} from './CenteredDialog.style';

export const CenteredDialog = ({
  children,
  title,
  closeDialog,
}: {
  children: JSX.Element;
  title: string;
  closeDialog: () => void;
}): JSX.Element => {
  return (
    <Dialog closeDialog={closeDialog}>
      <CenteredDialogContainer>
        <CenteredDialogHeader>
          <CenteredDialogTitle>{title}</CenteredDialogTitle>
          <CenteredDialogCloseButton
            buttonTheme="neutral"
            onClick={closeDialog}
            ariaLabel="close dialog button"
          ></CenteredDialogCloseButton>
        </CenteredDialogHeader>
        <CenteredDialogContent>{children}</CenteredDialogContent>
      </CenteredDialogContainer>
    </Dialog>
  );
};
