import styled from 'styled-components';
import { buttonText, getSpacing } from 'stylesheet';
import { colorUsage } from 'colors';

export const ButtonTextContainer = styled.button<{
  disabled: boolean;
  size?: 'normal' | 'tiny';
}>`
  border: none;
  border-radius: 4px;
  padding: ${(props) => (props.size === 'tiny' ? `0px 16px` : `8px 16px`)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorUsage.neutral.normal};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) =>
    props.disabled &&
    `
      opacity: 0.4;
      pointer-events: none;
    `}

  &:hover {
    background-color: ${(props) => props.theme.principal.light};
  }
`;

export const Label = styled.p(
  ({ theme }) => `
  color: ${theme.principal.normal};
  ${buttonText.medium};
  letter-spacing: 1px;
  white-space: nowrap;
  `,
);

export const LeftIconContainer = styled.div`
  margin-right: ${getSpacing(1)};
  display: flex;
`;
export const RightIconContainer = styled.div`
  margin-left: ${getSpacing(1)};
  display: flex;
`;
