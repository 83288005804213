import * as Sentry from '@sentry/browser';
import { ReactNode } from 'react';
import * as React from 'react';
import { IFallbackProps } from 'components/Core/AppCrashFallback/AppCrashFallback';

interface Props {
  children: ReactNode;
  FallbackComponent: React.ComponentType<IFallbackProps>;
}
interface State {
  hasError: boolean;
  eventId: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(): Record<string, boolean> {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, eventId: '' };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope((scope) => {
      const extras = {
        x: errorInfo.componentStack,
      };
      scope.setExtras(extras);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render(): ReactNode {
    const { hasError, eventId } = this.state;
    const { FallbackComponent, children } = this.props;
    return hasError ? <FallbackComponent eventId={eventId} /> : children;
  }
}

export default ErrorBoundary;
