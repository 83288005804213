import React from 'react';

import { colorUsage } from 'colors';

import { TooltipStyle } from './Tooltip.style';

const Tooltip: React.FC = () => {
  return <TooltipStyle.StyledReactTooltip arrowColor={colorUsage.neutral.dark} multiline={true} />;
};

export default Tooltip;
