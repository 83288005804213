import analyticsClient from 'services/analytics/analyticsClient';
import { getPageFromPath } from 'pages';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const usePageViewed = (): void => {
  const location: Location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    const page = getPageFromPath(pathname);
    if (page !== null) {
      analyticsClient.onPageViewed(page);
    }
  }, [location]);
};
