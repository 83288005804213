import * as Sentry from '@sentry/browser';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import configureStore from './redux/store';

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV, REACT_APP_VERSION } = process.env;

if (typeof REACT_APP_SENTRY_DSN !== 'undefined' && typeof REACT_APP_SENTRY_ENV !== 'undefined') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENV,
    release: REACT_APP_VERSION,
  });
}

const { store, persistor } = configureStore();

const rootEl = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!);

if (rootEl) {
  root.render(<App store={store} persistor={persistor} />);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line
    if (rootEl) {
      root.render(<NextApp store={store} persistor={persistor} />);
    }
  });
}

export default store;
