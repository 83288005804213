import { RootState } from 'redux/types';
import { Academy } from './slice';

export const getAcademy =
  (slug: string) =>
  (store: RootState): Academy => {
    return store.academy.academies[slug];
  };

export const getAcademies = (store: RootState): Academy[] => {
  const academies = [];
  for (const academy_idx in store.academy.academies) {
    academies.push(store.academy.academies[academy_idx]);
  }
  return academies;
};
