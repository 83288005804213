import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from 'redux/Course/types';
import { Skill } from 'redux/Skill';

export type AcademyStructureState = {
  academies_courses: Record<string, Record<string, string>>;
  academies_courses_skills: Record<string, Record<string, Record<string, string>>>;
};

const initialState: Readonly<AcademyStructureState> = {
  academies_courses: {},
  academies_courses_skills: {},
};

const academyStructureSlice = createSlice({
  name: 'academyStructureSlice',
  initialState,
  reducers: {
    addOneCourseInAcademyStructure: (state, action: PayloadAction<Course>) => {
      addCourse(state, action.payload);
    },
    addCoursesInAcademyStructureByAcademy: (
      state,
      action: PayloadAction<{ academySlug: string; courses: Course[] }>,
    ) => {
      const courses = action.payload.courses.filter(
        (course) => course.academy_slug === action.payload.academySlug,
      );
      if (courses.length > 0) {
        resetCourseListOfAcademy(state, action.payload.academySlug);
        courses.forEach((course) => {
          addCourse(state, course);
        });
      }
    },
    addOneSkillInAcademyStructure: (state, action: PayloadAction<Skill>) => {
      addSkill(state, action.payload);
    },
    addSkillsInAcademyStructureByAcademyAndCourse: (
      state,
      action: PayloadAction<{ academySlug: string; courseSlug: string; skills: Skill[] }>,
    ) => {
      const skills = action.payload.skills.filter(
        (skill) =>
          skill.academy_slug === action.payload.academySlug &&
          skill.course_slug === action.payload.courseSlug,
      );
      if (skills.length > 0) {
        resetSkillListOfCourse(state, action.payload.academySlug, action.payload.courseSlug);
        skills.forEach((skills) => {
          addSkill(state, skills);
        });
      }
    },
    moveCourse: (
      state,
      action: PayloadAction<{ academy_slug: string; from: number; to: number }>,
    ) => {
      const { academy_slug, from, to } = action.payload;
      let newCourseList = Object.entries(state.academies_courses[academy_slug]);
      const movedCourse = newCourseList.splice(from, 1)[0];
      newCourseList = [...newCourseList.slice(0, to), movedCourse, ...newCourseList.slice(to)];
      state.academies_courses[academy_slug] = {};
      newCourseList.forEach((course) => {
        state.academies_courses[academy_slug][course[0]] = course[1];
      });
    },
    moveSkill: (
      state,
      action: PayloadAction<{
        academy_slug: string;
        course_slug: string;
        from: number;
        to: number;
      }>,
    ) => {
      const { academy_slug, course_slug, from, to } = action.payload;
      let newSkillList = Object.entries(state.academies_courses_skills[academy_slug][course_slug]);
      const movedSkill = newSkillList.splice(from, 1)[0];
      newSkillList = [...newSkillList.slice(0, to), movedSkill, ...newSkillList.slice(to)];
      state.academies_courses_skills[academy_slug][course_slug] = {};
      newSkillList.forEach((skill) => {
        state.academies_courses_skills[academy_slug][course_slug][skill[0]] = skill[1];
      });
    },
  },
});

const addCourse = (state: AcademyStructureState, course: Course): void => {
  if (course.slug !== undefined && course.slug !== '') {
    if (!state.academies_courses.hasOwnProperty(course.academy_slug)) {
      state.academies_courses[course.academy_slug] = {};
    }

    state.academies_courses[course.academy_slug][course.slug] = course.uuid;
  }
};

const addSkill = (state: AcademyStructureState, skill: Skill): void => {
  if (skill.slug !== undefined && skill.slug !== '') {
    if (!state.academies_courses_skills.hasOwnProperty(skill.academy_slug)) {
      state.academies_courses_skills[skill.academy_slug] = {};
    }
    if (!state.academies_courses_skills[skill.academy_slug].hasOwnProperty(skill.course_slug)) {
      state.academies_courses_skills[skill.academy_slug][skill.course_slug] = {};
    }

    state.academies_courses_skills[skill.academy_slug][skill.course_slug][skill.slug] = skill.uuid;
  }
};

const resetSkillListOfCourse = (
  state: AcademyStructureState,
  academySlug: string,
  courseSlug: string,
): void => {
  if (
    state.academies_courses_skills.hasOwnProperty(academySlug) &&
    state.academies_courses_skills[academySlug].hasOwnProperty(courseSlug)
  ) {
    state.academies_courses_skills[academySlug][courseSlug] = {};
  }
};

const resetCourseListOfAcademy = (state: AcademyStructureState, academySlug: string): void => {
  if (state.academies_courses.hasOwnProperty(academySlug)) {
    state.academies_courses[academySlug] = {};
  }
};

export const {
  addOneSkillInAcademyStructure,
  addSkillsInAcademyStructureByAcademyAndCourse,
  addOneCourseInAcademyStructure,
  addCoursesInAcademyStructureByAcademy,
  moveCourse,
  moveSkill,
} = academyStructureSlice.actions;
export default academyStructureSlice.reducer;
