import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Skill } from './types';

export type SkillState = Readonly<{
  skills: Record<string, Skill>;
}>;

const initialState: SkillState = { skills: {} };

const skillsSlice = createSlice({
  name: 'Skill',
  initialState,
  reducers: {
    addSkills: (state, action: PayloadAction<Array<Skill>>) => {
      action.payload.forEach((skill: Skill) => {
        state.skills[skill.uuid] = skill;
      });
    },
    removeSkill: (state, action: PayloadAction<string>) => {
      if (action.payload in state.skills) {
        delete state.skills[action.payload];
      }
    },
  },
});

export const { addSkills, removeSkill } = skillsSlice.actions;
export default skillsSlice.reducer;
