export const objectFlip = (object: Record<string, string>): Record<string, string> =>
  Object.fromEntries(Object.entries(object).map((entry) => entry.reverse()));

export const isStringDefined = (element: string | undefined | null): boolean =>
  element !== undefined && element !== null && element !== '';

export function humanizeSlug(slug: string): string {
  let result = slug.replace(/-|_/g, ' ');

  result = result.replace(/\b\w/g, (char) => char.toUpperCase());

  return result;
}
