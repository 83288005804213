import React from 'react';
import ButtonIcon from '../ButtonIcon';
import { CloseIcon } from 'components/UI/SvgIcon/CloseIcon';
import { ButtonIconTheme } from '../ButtonIcon/ButtonIcon.style';

const ButtonClose = ({
  onClick,
  buttonTheme,
  className,
  ariaLabel = 'close button',
}: {
  onClick: () => void;
  buttonTheme?: ButtonIconTheme;
  className?: string;
  ariaLabel?: string;
}): JSX.Element => {
  return (
    <ButtonIcon
      className={className}
      icon={<CloseIcon />}
      onClick={onClick}
      buttonTheme={buttonTheme}
      ariaLabel={ariaLabel}
    ></ButtonIcon>
  );
};

export default ButtonClose;
