import styled from 'styled-components';
import { text, header, getSpacing, fontWeight, fontFamily } from 'stylesheet';
import { colorUsage } from 'colors';
import { AcademyTheme } from 'AcademyThemeProvider';

export const richTextCodeStyle = (): string =>
  `pre {
    &[class*='language-'] {
      background: ${colorUsage.neutral.dark};
      margin-top: 0;
      padding: 0;
    }

    & > code {
      display: block;
      padding: ${getSpacing(2)};
    }
  }

  code {
    font-family: ${fontFamily.code};
    padding: ${getSpacing(0.5)} ${getSpacing(1)};
    background: ${colorUsage.neutral.dark};
    color: ${colorUsage.neutral.light};
    border-radius: ${getSpacing(0.5)};
    overflow-x: auto;
  }`;

export const RichTextComponentStyled = styled.div<{ theme: AcademyTheme }>`
  ${text.medium};
  max-width: inherit;

  /* stylelint-disable */
  h1 {
    ${header.xLarge}
  }

  h2 {
    ${header.large}
  }

  h3 {
    ${header.medium}
  }

  h4 {
    ${text.large_bold}
  }

  ${richTextCodeStyle()}

  table {
    border-radius: ${getSpacing(0.5)};
    overflow: hidden;
    max-width: inherit;

    td,
    th {
      padding: ${getSpacing(2)};
    }

    td {
      border-bottom: solid 1px ${colorUsage.neutral.op_20};
    }

    th {
      background-color: ${colorUsage.neutral.light};
      font-weight: ${fontWeight.bold};
    }
  }

  ol,
  ul {
    display: block;
    padding-left: 2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: ${getSpacing(2)};
    margin-bottom: ${getSpacing(2)};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul,
  p,
  code,
  pre,
  table {
    margin-bottom: ${getSpacing(2)};

    &:last-child {
      margin-bottom: 0;
    }
  }
  figure {
    margin: auto;
    &.image-style-align-center {
      margin-left: auto;
      margin-right: auto;
    }
    figcaption {
      text-align: center;
    }
    img {
      width: 100%;
      max-width: inherit;
    }
  }
  i {
    font-style: italic;
  }
  a {
    color: ${({ theme }) => theme.principal.normal};
    border-bottom: ${({ theme }) => `2px solid ${theme.principal.normal}`};
  }
  /* stylelint-enable */
`;
