import styled from 'styled-components';
import { buttonText, getSpacing } from 'stylesheet';
import { colorUsage } from 'colors';

export const ButtonLinkContainer = styled.button`
  margin: ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorUsage.neutral.normal};
  cursor: pointer;
`;

export const Label = styled.p`
  color: ${colorUsage.neutral.normal};
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  ${buttonText.medium};
  letter-spacing: 1px;
  white-space: nowrap;
`;
