export const LOCALSTORAGE = {
  DEEP_LINK: 'deeplink',
};

export const getDeepLink = (): string | null => {
  return localStorage.getItem(LOCALSTORAGE.DEEP_LINK);
};

export const setDeepLink = (url: string): void => {
  localStorage.setItem(LOCALSTORAGE.DEEP_LINK, url);
};

export const reinitiliazeDeepLink = (): void => {
  localStorage.removeItem(LOCALSTORAGE.DEEP_LINK);
};
