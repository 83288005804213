import styled from 'styled-components';
import { borderRadius, fontFamily, fontSize, fontWeight, getSpacing, lineHeight } from 'stylesheet';
import { colorUsage } from 'colors';
import { AcademyTheme } from 'AcademyThemeProvider';

export type ButtonContainedTheme = 'principal' | 'accent' | 'neutral';

const backgroundColor = (theme: AcademyTheme, buttonTheme: ButtonContainedTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return theme.principal.normal;
    case 'accent':
      return colorUsage.accent.normal;
    case 'neutral':
      return colorUsage.neutral.normal;
  }
};

const addAlpha = (color: string, opacity: number): string => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

const hoverBackgroundColor = (theme: AcademyTheme, buttonTheme: ButtonContainedTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return addAlpha(theme.principal.normal, 0.2);
    case 'accent':
      return colorUsage.accent.op_20;
    case 'neutral':
      return colorUsage.neutral.op_20;
  }
};

export const ButtonContainedStyled = styled.button<{
  buttonTheme: ButtonContainedTheme;
  theme: AcademyTheme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  min-width: 137px;
  background-color: ${(props) => backgroundColor(props.theme, props.buttonTheme)};
  border-radius: ${borderRadius.medium};
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  color: ${colorUsage.accent.light};

  &:disabled {
    opacity: 0.4;
  }

  &:hover:enabled {
    box-shadow: 0 4px 0 ${({ theme, buttonTheme }) => hoverBackgroundColor(theme, buttonTheme)};
  }

  cursor: ${({ disabled = false }) => (disabled ? 'default' : 'pointer')};
  border: none;
`;

export const LeftIconContainer = styled.div`
  display: flex;
  margin-right: ${getSpacing(1)};
`;
export const RightIconContainer = styled.div`
  display: flex;
  margin-left: ${getSpacing(1)};
`;
export const ButtonLabel = styled.p``;

export default ButtonContainedStyled;
