import store from 'index';
import { academiesFetched, Academy } from 'redux/Academy';
import client from 'services/networking/client';

export async function fetchAcademies(): Promise<Academy[]> {
  try {
    if (store.getState().academy.academies.length > 0) {
      return store.getState().academy.academies.length;
    } else {
      const academiesResponse = await client.get(`${process.env.REACT_APP_API_URL_CODE}academies/`);
      const academies: Academy[] = academiesResponse.results;
      if (academies.length > 0) {
        store.dispatch(academiesFetched(academies));
      }
      return academies;
    }
  } catch (err) {
    return [];
  }
}

export async function fetchAcademy(academy_slug: string): Promise<Academy | undefined | null> {
  try {
    const newAcademy: Academy | undefined = await client.get(
      `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/`,
    );
    return newAcademy;
  } catch (err) {
    return null;
  }
}
