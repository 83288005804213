import React from 'react';
import styled from 'styled-components';
import Icon from './SvgIcon';

export const Svg = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const CloseIcon = (): JSX.Element => (
  <Svg viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.393819 13.7049C-0.131273 14.23 -0.131273 15.0813 0.393819 15.6064C0.918911 16.1315 1.77026 16.1315 2.29535 15.6064L8.00024 9.90153L13.7046 15.6059C14.2297 16.131 15.081 16.131 15.6061 15.6059C16.1312 15.0808 16.1312 14.2295 15.6061 13.7044L9.90177 8L15.606 2.29573C16.1311 1.77064 16.1311 0.919297 15.606 0.394204C15.0809 -0.130887 14.2296 -0.130885 13.7045 0.394206L8.00025 6.09847L2.29544 0.393671C1.77035 -0.13142 0.919009 -0.13142 0.393917 0.393671C-0.131175 0.918763 -0.131174 1.77011 0.393919 2.2952L6.09872 8L0.393819 13.7049Z"
      fill="currentColor"
    />
  </Svg>
);
