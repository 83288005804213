import React from 'react';
import styled from 'styled-components';

const ClickPropagationStopStyled = styled.div`
  display: contents;
`;

const ClickPropagationStop = ({ children }: { children?: JSX.Element }): JSX.Element => {
  return (
    <ClickPropagationStopStyled onClick={(e) => e.stopPropagation()}>
      {children}
    </ClickPropagationStopStyled>
  );
};

export default ClickPropagationStop;
