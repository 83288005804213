import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AcademyPages, Pages } from 'pages';
import { ErrorStatus } from 'services/networking/types';

export const isNavigationIsPossible = (requestParams: (string | undefined)[]): boolean => {
  for (const requestParam of requestParams) {
    if (requestParam === '' || requestParam === undefined || requestParam === null) {
      return false;
    }
  }
  return true;
};

export const navigateIfPossible = (
  requestParams: (string | undefined)[],
  navigate: () => void,
  fallback: () => void,
): void => {
  if (isNavigationIsPossible(requestParams)) {
    navigate();
  } else {
    fallback();
  }
};

export const basePath = (isDesigner = false): string => {
  return Pages.academyHome.pathname + (isDesigner ? '/' + AcademyPages.designer.pathname : '');
};

export const useNavigateToPageNotFound = (): { navigateToPageNotFound: () => void } => {
  const history = useHistory();
  const navigateToPageNotFound = useCallback(() => {
    history.push(Pages.notFound);
  }, [history]);
  return { navigateToPageNotFound };
};

export const useNavigateToPageForbidden = (): { navigateToPageForbidden: () => void } => {
  const history = useHistory();
  const navigateToPageForbidden = useCallback(() => {
    history.push(Pages.forbidden);
  }, [history]);
  return { navigateToPageForbidden };
};

export const useNavigateToPageError = (): { navigateToPageError: (error: ErrorStatus) => void } => {
  const { navigateToPageNotFound } = useNavigateToPageNotFound();
  const { navigateToPageForbidden } = useNavigateToPageForbidden();
  const navigateToPageError = useCallback(
    (error: ErrorStatus) => {
      if (error === 403) {
        navigateToPageForbidden();
      }
      if (error === 404) {
        navigateToPageNotFound();
      }
    },
    [navigateToPageNotFound, navigateToPageForbidden],
  );
  return { navigateToPageError };
};

export const useNavigateToLoginPage = (): { navigateToLoginPage: () => void } => {
  const history = useHistory();
  const navigateToLoginPage = useCallback(() => {
    history.push(Pages.login);
  }, [history]);
  return { navigateToLoginPage };
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useIsInEmbedCycle = (): boolean => {
  const query = useQuery();
  return query.get('embed') === 'true';
};

export const useScrollToTop = (): void => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
