import * as React from 'react';

import { Provider } from 'react-redux';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { hotjar } from 'react-hotjar';
import { Toaster } from 'react-hot-toast';

import Router from 'components/Router/Router';
import { BrowserRouter } from 'react-router-dom';
import AppCrashFallback from 'components/Core/AppCrashFallback';
import ErrorBoundary from 'components/Core/ErrorBoundary';
import Root from 'components/Core/Root';
import { DialogWrapper } from 'components/UI/Dialog/DialogWrapper/DialogWrapper';
import Auth0ProviderWrapper from 'components/Core/Auth0ProviderWrapper/Auth0ProviderWrapper';
import IntlProviderWrapper from 'components/Core/IntlProviderWrapper/IntlProviderWrapper';
import Tooltip from 'components/UI/Tooltip';
import { ThemeProvider } from 'styled-components';
import { colorUsage } from 'colors';

hotjar.initialize(2398942, 6);

interface Props {
  persistor: Persistor;
  store: Store;
}

const App: React.FunctionComponent<Props> = ({ persistor, store }) => (
  <ErrorBoundary FallbackComponent={AppCrashFallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWrapper>
            <IntlProviderWrapper>
              <ThemeProvider theme={colorUsage}>
                <Toaster />
                <Root>
                  {/* Wrap your component with TooltipRebuild if you can't see it. More explanations in the TooltipRebuild component */}
                  <Tooltip />
                  <Router />
                </Root>
                <DialogWrapper />
              </ThemeProvider>
            </IntlProviderWrapper>
          </Auth0ProviderWrapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

export default App;
