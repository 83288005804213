import React from 'react';
import { useIntl } from 'react-intl';
import { useDialog, useHideDialog } from 'redux/Dialog/hooks';
import { CenteredDialog } from '../CenteredDialog/CenteredDialog';
import {
  AccessDeniedDialogAlert,
  AccessDeniedDialogConfirmButton,
  AccessDeniedDialogContent,
  AccessDeniedDialogAskAccess,
} from './AccessDeniedDialog.style';
import { useAuth0 } from '@auth0/auth0-react';
import { Pages } from 'pages';

export const AccessDeniedDialog = (): JSX.Element => {
  const { handleOnClose } = useHideDialog();
  const intl = useIntl();
  const { dialog } = useDialog();
  const { logout } = useAuth0();
  const handleClose = () => {
    handleOnClose();
    logout({ logoutParams: { returnTo: window.location.origin + Pages.login.pathname } });
  };

  return (
    <CenteredDialog
      title={intl.formatMessage({ id: 'dialog.access-denied.title' })}
      closeDialog={handleClose}
    >
      <AccessDeniedDialogContent>
        <AccessDeniedDialogAlert>
          {intl.formatMessage(
            { id: 'dialog.access-denied.alert-text' },
            { email: dialog.emailUserDenied },
          )}
        </AccessDeniedDialogAlert>
        <AccessDeniedDialogAskAccess>
          {intl.formatMessage({ id: 'dialog.access-denied.ask-access' })}
        </AccessDeniedDialogAskAccess>
        <AccessDeniedDialogConfirmButton
          buttonTheme="neutral"
          buttonLabel={intl.formatMessage({ id: 'dialog.access-denied.confirm-button' })}
          onClick={handleClose}
          ariaLabel="confirm-button"
        ></AccessDeniedDialogConfirmButton>
      </AccessDeniedDialogContent>
    </CenteredDialog>
  );
};
