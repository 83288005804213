import { Skill } from 'redux/Skill';
import client from 'services/networking/client';
import { ErrorStatus, RequestResult } from './types';

interface SkillsResponse {
  count: number;
  next: string;
  previous: string;
  results: Skill[];
}

export async function fetchSkillsByAcademyAndCourse(
  academy_slug: string,
  course_slug: string,
): Promise<{ skills: Skill[] | null; error?: ErrorStatus }> {
  try {
    const skills = await fetchSkills(academy_slug, course_slug);
    return { skills };
  } catch (e: any) {
    if ([403, 404].includes(e.status)) {
      return { skills: null, error: e.status };
    }
    return { skills: null };
  }
}

export async function fetchOneSkillByAcademyAndCourse(
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): Promise<{ skill: Skill | null; error?: ErrorStatus }> {
  try {
    const skill = await fetchSkill(academy_slug, course_slug, skill_slug);
    return { skill };
  } catch (e: any) {
    if ([403, 404].includes(e.status)) {
      return { skill: null, error: e.status };
    }
    return { skill: null };
  }
}

async function fetchSkill(
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): Promise<Skill> {
  const endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/${skill_slug}/`;
  const skill: Skill = await client.get(endpoint);
  return skill;
}

async function fetchSkills(academy_slug: string, course_slug: string): Promise<Skill[]> {
  const endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/`;
  const response: SkillsResponse = await client.get(endpoint);
  return response.results;
}

export const updateSkill = async (updatedSkill: FormData): Promise<Skill | null> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${updatedSkill.get(
      'academy_slug',
    )}/courses/${updatedSkill.get('course_slug')}/skills/${updatedSkill.get('initial_slug')}/`;
    return await client.patch(api_endpoint, updatedSkill);
  } catch (e) {
    return null;
  }
};

export type SkillToCreate = {
  academy_slug: string;
  course_slug: string;
  slug: string;
  name: string;
};

export const createSkill = async (skillToCreate: SkillToCreate): Promise<Skill | null> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${skillToCreate.academy_slug}/courses/${skillToCreate.course_slug}/skills/`;
    return await client.post(api_endpoint, skillToCreate);
  } catch (e) {
    return null;
  }
};

export const deleteSkill = async (
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): Promise<RequestResult> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/${skill_slug}/`;
    await client.delete(api_endpoint);
    return 'OK';
  } catch (e) {
    return 'ERROR';
  }
};

export const orderSkillsByCourse = async (
  academy_slug: string,
  course_slug: string,
  skills_uuid: string[],
): Promise<RequestResult> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/order`;
    await client.post(endpoint, { order: skills_uuid });
    return 'OK';
  } catch (e) {
    return 'ERROR';
  }
};
