import React from 'react';
import ClickPropagationStop from 'components/Core/ClickPropagationStop/ClickPropagationStop';
import DialogPortal from 'components/UI/Portal/Portal';
import { DialogFullScreenWrapper, DialogPageContainer } from './Dialog.style';
import { useZoomableImages } from 'hooks';

const Dialog = ({
  hasBackgroundOverlay = true,
  children,
  closeDialog,
  alignY,
}: {
  hasBackgroundOverlay?: boolean;
  children?: JSX.Element;
  closeDialog?: () => void;
  alignY?: 'center' | 'flex-end' | 'flex-start';
}): JSX.Element => {
  useZoomableImages();
  return (
    <DialogPortal>
      <DialogFullScreenWrapper
        alignY={alignY}
        hasBackgroundOverlay={hasBackgroundOverlay}
        onClick={closeDialog}
      >
        <DialogPageContainer>
          <ClickPropagationStop>{children}</ClickPropagationStop>
        </DialogPageContainer>
      </DialogFullScreenWrapper>
    </DialogPortal>
  );
};

export default Dialog;
