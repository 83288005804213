import React from 'react';
import { LoaderWrapper, LoaderImage } from './Loader.style';
import logo from 'assets/logo.png';

const Loader = ({ className }: { className?: string }): JSX.Element => (
  <LoaderWrapper className={className}>
    <LoaderImage src={logo} alt="loading" height={96} />
  </LoaderWrapper>
);

export default Loader;
