import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from './types';

export type CourseState = Readonly<{
  courses: Record<string, Course>;
}>;

const initialState: CourseState = { courses: {} };

const coursesSlice = createSlice({
  name: 'Course',
  initialState,
  reducers: {
    addCourses: (state, action: PayloadAction<Array<Course>>) => {
      action.payload.forEach((course: Course) => {
        if (state.courses === undefined) {
          state.courses = {};
        }
        state.courses[course.uuid] = course;
      });
    },
    removeCourse: (state, action: PayloadAction<string>) => {
      if (action.payload in state.courses) {
        delete state.courses[action.payload];
      }
    },
  },
});

export const { addCourses, removeCourse } = coursesSlice.actions;
export default coursesSlice.reducer;
