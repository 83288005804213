import styled, { keyframes } from 'styled-components';
import Loader from './Loader';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderImage = styled.img`
  animation: ${spin} 2s linear infinite;
`;

export const LoaderCentered = styled(Loader)`
  height: 100vh;
`;

export const LoaderCenteredInPage = styled(Loader)`
  height: 100%;
`;

export const LowLoader = styled(Loader)`
  height: 130px;
`;
