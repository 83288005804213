import RichTextComponent from 'components/UI/RichTextComponent/RichTextComponent';
import styled from 'styled-components';
import { getSpacing, header, text } from 'stylesheet';
import { colorUsage } from 'colors';
import ButtonIcon from 'components/UI/Button/ButtonIcon';

export const KeyPointsDialogContainer = styled.div`
  height: 100vh;
  padding: ${getSpacing(10)} 0;
`;

export const KeyPointsDialogContent = styled.div`
  background-color: ${colorUsage.white.normal};
  max-width: ${getSpacing(135)};
  height: 100%;
  margin: auto;
  border: 1px solid rgb(122 71 10 / 20%);
  border-radius: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const KeyPointsDialogTitleContainer = styled.div`
  display: flex;
  position: relative;
  ${header.medium};
  color: ${colorUsage.neutral.normal};
  padding: ${getSpacing(2)} 0;
`;

export const KeyPointsDialogTitle = styled.h3`
  width: 100%;
  text-align: center;
  padding-top: ${getSpacing(1)};
`;
export const KeyPointsDialogButtonClose = styled(ButtonIcon)`
  position: absolute;
  right: ${getSpacing(2)};
`;

export const Separator = styled.hr`
  margin-top: ${getSpacing(2)};
  border-bottom: 1px solid ${colorUsage.neutral.op_20};
`;

export const SkillStandard = styled(RichTextComponent)`
  overflow-y: auto;
  padding: ${getSpacing(4)};
`;
export const SkillStandardMissing = styled.p`
  ${text.medium};
  text-align: center;
  padding: ${getSpacing(4)};
`;
