import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { RichTextComponentStyled } from './RichTextComponent.style';
import Prism from 'prismjs';

const AUTHORIZED_IFRAME_SOURCES = [
  'https://www.youtube.com/embed/',
  '//www.youtube.com/embed/',
  'https://player.vimeo.com/video/',
];

const RichTextComponent = ({
  className,
  htmlContent,
}: {
  className?: string;
  htmlContent: string;
}): JSX.Element => {
  useEffect(() => {
    Prism.highlightAll();
  }, [htmlContent]);

  useEffect(() => {
    DOMPurify.addHook(
      'uponSanitizeElement',
      (node: Element, data: DOMPurify.SanitizeElementHookEvent) => {
        if (data.tagName === 'iframe') {
          const src = node.getAttribute('src') ?? '';
          let isSourceAllowed = false;
          for (const authorizedSource of AUTHORIZED_IFRAME_SOURCES) {
            if (src.startsWith(authorizedSource)) {
              isSourceAllowed = true;
              break;
            }
          }
          if (!isSourceAllowed) {
            if (node !== null && node.parentNode !== null) {
              return node.parentNode.removeChild(node);
            }
          }
        }
      },
    );
    return () => DOMPurify.removeHook('uponSanitizeElement');
  }, []);

  useEffect(() => {
    DOMPurify.addHook('uponSanitizeAttribute', (node: Element) => {
      if (node.hasAttribute('href')) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });

    return () => DOMPurify.removeHook('afterSanitizeAttributes');
  }, []);

  return (
    <RichTextComponentStyled
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(htmlContent, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allowfullscreen', 'target'],
        }),
      }}
    />
  );
};

export default RichTextComponent;
