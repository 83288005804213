import { RootState } from 'redux/types';
import { Skill } from 'redux/Skill';

export const getSkills = (store: RootState): Record<string, Skill> => {
  return store.skill.skills;
};

export const getSkillsByUuid =
  (uuid_list: string[]) =>
  (store: RootState): Skill[] => {
    const skills: Skill[] = [];
    uuid_list.forEach((uuid) => {
      if (store.skill.skills.hasOwnProperty(uuid)) {
        skills.push(store.skill.skills[uuid]);
      }
    });
    return skills;
  };

export const getSkill =
  (uuid: string) =>
  (store: RootState): Skill | undefined => {
    return store.skill.skills[uuid];
  };
