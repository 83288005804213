import client from 'services/networking/client';
import { Academy } from 'redux/Academy';

export const updateAcademy = async (updatedAcademy: FormData): Promise<Academy | null> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${updatedAcademy.get(
      'slug',
    )}/`;
    return await client.patch(api_endpoint, updatedAcademy);
  } catch (e) {
    return null;
  }
};
