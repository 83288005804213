import store from 'index';
import jwt_decode from 'jwt-decode';
import request from 'superagent';
import { getUserToken } from 'redux/Login/selectors';
import { userLoggedOut } from 'redux/Login';
import { ClientPatchData, ClientPostData, ClientPutData } from './types';

const backendBaseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

interface AccessToken {
  exp: number;
}

function tokenHasExpired(token: AccessToken): boolean {
  if (!token.exp) return true;

  // Less than 10 seconds remaining => token has expired
  const now = new Date().getTime() / 1000;
  return token.exp - now < 10;
}

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

class Client {
  baseUrl: string;
  withCredentials: boolean;
  agent: request.SuperAgentStatic & request.Request;

  constructor(baseUrl: string, withCredentials = true) {
    this.baseUrl = baseUrl;
    this.withCredentials = withCredentials;
    this.agent = request.agent();
    this.agent.accept('application/json');
    if (withCredentials) {
      this.agent.withCredentials();
    }
  }

  async request(
    method: Method,
    endpoint: string,
    data: ClientPatchData | ClientPostData | ClientPutData = null,
    checkToken = true,
  ) {
    try {
      if (this.withCredentials) {
        if (checkToken) await this.checkToken();
      }

      const url = /^https?:\/\//.test(endpoint) ? endpoint : `${this.baseUrl}${endpoint}`;
      const promise = this.agent[method](url);

      this.setAuthorizationHeader(promise);
      this.addDataToRequest(promise, method, data);

      const { body } = await promise;
      return body;
    } catch (err: any) {
      if (err.status === 401) {
        store.dispatch(userLoggedOut());
      } else {
        throw err;
      }
    }
  }

  setAuthorizationHeader(promise: request.SuperAgentRequest) {
    const token = this.getToken();

    if (this.withCredentials && this.isTokenValid(token)) {
      promise = promise.set('Authorization', `Bearer ${token}`);
    }
  }

  addDataToRequest(
    promise: request.SuperAgentRequest,
    method: Method,
    data: ClientPatchData | ClientPostData | ClientPutData = null,
  ) {
    if (['post', 'put', 'patch'].includes(method) && data !== null && data !== undefined) {
      promise = promise.send(data);
    }
  }

  isTokenValid(token: string | null): boolean {
    return token !== null && token !== '';
  }

  getToken() {
    return getUserToken(store.getState());
  }

  /**
   * This function assess the access token is still valid, if not it refreshes it.
   * In case of error during the refresh process it disconnects the user and redirects to the login page.
   */
  async checkToken() {
    const token = this.getToken();

    // There was no token to begin with, nothing to check.
    if (token === null || token === '') return;

    const parsedToken = jwt_decode<AccessToken>(token);
    if (tokenHasExpired(parsedToken)) {
      store.dispatch(userLoggedOut());
    }
  }

  get(endpoint: string) {
    return this.request('get', endpoint);
  }

  post(endpoint: string, data: ClientPostData) {
    return this.request('post', endpoint, data);
  }

  put(endpoint: string, data: ClientPutData) {
    return this.request('put', endpoint, data);
  }

  patch(endpoint: string, data: ClientPatchData = null) {
    return this.request('patch', endpoint, data);
  }
  delete(endpoint: string) {
    return this.request('delete', endpoint);
  }
}

const client = new Client(backendBaseUrl, true);

export default client;
