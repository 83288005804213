import { colorUsage } from 'colors';

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';

export const DESIGNER_HEADER_HEIGHT = 56;

export const zIndexes = { little: 2, middle: 10, top: 99 };

export const fontFamily = {
  main: `'Inter', 'Helvetica', 'Arial', sans-serif`,
  code: `Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace`,
};

export const fontSize = {
  XXLarge: '60px',
  large: '24px',
  medium: '16px',
  small: '14px',
  XSmall: '12px',
};

export const fontWeight = {
  bolder: '900',
  bold: '700',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
  small: '12px',
};

export const borderRadius = { medium: '4px', large: '10px', xLarge: '15px', xxLarge: '18px' };

export const text = {
  small: `font-size: 12px; font-weight: 600; letter-spacing: 0.1em; font-family:${fontFamily.main}; line-height: 22px; min-height: 22px; text-transform: uppercase;`,

  medium: `font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  min-height: 24px;
  text-align: left;
  letter-spacing: 0em;
  font-family:${fontFamily.main};`,

  medium_bold: `font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  min-height: 24px:
  text-align: left;
  font-family:${fontFamily.main};`,

  large: `font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  min-height: 28px;
  font-style: normal;
  text-align: left;
  letter-spacing: 0em;
  color:${colorUsage.neutral.normal};
  font-family:${fontFamily.main};`,

  large_bold: `font-family:${fontFamily.main}; font-size: 20px; font-weight: 700; line-height: 28px; min-height: 28px; letter-spacing: 0em; text-align:left;`,
};

export const header = {
  medium: `font-weight: 900;font-size: 24px; line-height: 32px; min-height: 32px; letter-spacing: 0.02em; color:${colorUsage.neutral.normal};font-family:${fontFamily.main};`,
  large: `font-weight: 900;font-size: 40px; line-height: 48px; min-height: 48px; letter-spacing: 0.02em; color:${colorUsage.neutral.normal};font-family:${fontFamily.main};`,
  xLarge: `font-weight: 900;font-size: 48px; line-height: 56px; min-height: 56px; letter-spacing: 0.02em; font-family:${fontFamily.main};`,
};

export const buttonText = {
  medium: `font-size: 16px; font-style: normal; font-weight: 700; line-height: 24px; min-height: 24px; letter-spacing: 0.05em; font-family: ${fontFamily.main};`,
};

export const shadowCompletion = `box-shadow: 48px 4px 100px rgba(2, 200, 129, 0.1), -32px 4px 100px rgba(0, 23, 229, 0.1);`;

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const screenSizes = {
  lg: 1080,
  xl: 1440,
  xxl: 1920,
};
