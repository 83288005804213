import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Row = styled.div<{ grow?: boolean; flex?: boolean }>`
  margin-left: ${getSpacing(4)};
  margin-right: ${getSpacing(4)};
  ${({ flex = false }) =>
    flex
      ? `
      display: flex;
      flex-direction: column;
    `
      : `
      display: block;
   `};
  ${({ grow = false }) =>
    grow &&
    `
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
  `}
  max-height: 100%;
`;
