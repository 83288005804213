import ButtonContained from 'components/UI/Button/ButtonContained';
import styled from 'styled-components';
import { getSpacing, text } from 'stylesheet';

export const AccessDeniedDialogAlert = styled.p`
  margin-bottom: ${getSpacing(3)};
  ${text.medium};
  text-align: center;
`;
export const AccessDeniedDialogAskAccess = styled.p`
  margin-bottom: ${getSpacing(6)};
  ${text.medium};
  text-align: center;
`;
export const AccessDeniedDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const AccessDeniedDialogConfirmButton = styled(ButtonContained)`
  margin-bottom: ${getSpacing(4)};
`;
