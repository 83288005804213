import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Focus } from './types';

export type FocusState = Readonly<{
  focuses: Focus[];
}>;

const initialState: FocusState = { focuses: [] };

const focusesSlice = createSlice({
  name: 'Focus',
  initialState,
  reducers: {
    setFocuses: (state, action: PayloadAction<Focus[]>) => {
      state.focuses = action.payload;
    },
    updateFocuses: (state, action: PayloadAction<Focus[]>) => {
      const updatedFocuses = state.focuses.map(
        (val) => action.payload.find((focus: Focus) => focus.uuid === val.uuid) || val,
      );
      state.focuses = updatedFocuses;
    },
    addFocuses: (state, action: PayloadAction<Focus[]>) => {
      action.payload.forEach((focus: Focus) => {
        state.focuses.push(focus);
      });
    },
    removeFocus: (state, action: PayloadAction<string>) => {
      state.focuses = state.focuses.filter((focus) => focus.uuid !== action.payload);
    },
  },
});

export const { addFocuses, updateFocuses, removeFocus, setFocuses } = focusesSlice.actions;
export default focusesSlice.reducer;
