import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Simulator } from './types';

export type SimulatorState = Readonly<{
  simulators: Record<string, Simulator>;
}>;

const initialState: SimulatorState = { simulators: {} };

const simulatorsSlice = createSlice({
  name: 'Simulator',
  initialState,
  reducers: {
    addSimulators: (state, action: PayloadAction<Array<Simulator>>) => {
      action.payload.forEach((simulator: Simulator) => {
        state.simulators[simulator.uuid] = simulator;
      });
    },
  },
});

export const { addSimulators } = simulatorsSlice.actions;
export default simulatorsSlice.reducer;
