import styled from 'styled-components';
import { colorUsage } from 'colors';
import { getSpacing } from 'stylesheet';
import { AcademyTheme } from 'AcademyThemeProvider';

export type ButtonIconTheme = 'principal' | 'neutral' | 'accent';

const iconHoverBackgroundColor = (theme: AcademyTheme, buttonTheme: ButtonIconTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return theme.principal.light;
    case 'accent':
      return colorUsage.accent.op_20;
    case 'neutral':
      return colorUsage.neutral.light;
  }
};

const iconHoverColor = (theme: AcademyTheme, buttonTheme: ButtonIconTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return theme.principal.normal;
    case 'accent':
      return colorUsage.accent.dark;
    case 'neutral':
      return colorUsage.neutral.dark;
  }
};

const borderHoverColor = (theme: AcademyTheme, buttonTheme: ButtonIconTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return theme.principal.normal;
    case 'accent':
      return colorUsage.accent.normal;
    case 'neutral':
      return colorUsage.neutral.normal;
  }
};

const borderColor = (buttonTheme: ButtonIconTheme): string => {
  switch (buttonTheme) {
    case 'principal':
      return colorUsage.neutral.op_20;
    case 'accent':
      return colorUsage.accent.d_op_20;
    case 'neutral':
      return 'transparent';
  }
};

export const IconContainer = styled.button<{ buttonTheme: ButtonIconTheme; theme: AcademyTheme }>`
  background-color: ${colorUsage.white.normal};
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  border: ${({ buttonTheme }) => `2px solid ${borderColor(buttonTheme)}`};
  cursor: pointer;
  height: ${getSpacing(5)};

  .svg-icon {
    color: ${colorUsage.neutral.normal};
    opacity: 0.6;
  }

  &:hover {
    background-color: ${({ theme, buttonTheme }) => iconHoverBackgroundColor(theme, buttonTheme)};
    border: ${({ theme, buttonTheme }) => `2px solid ${borderHoverColor(theme, buttonTheme)}`};

    .svg-icon {
      color: ${({ theme, buttonTheme }) => iconHoverColor(theme, buttonTheme)};
      opacity: 1;
    }
  }
`;
