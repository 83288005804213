import React, { useMemo } from 'react';
import { Container, Row } from 'components/UI/Layout';
import { CloseIcon } from 'components/UI/SvgIcon/CloseIcon';
import { useIntl } from 'react-intl';
import { useDialog, useHideDialog } from 'redux/Dialog/hooks';
import { useGetSkill } from 'redux/Skill/hooks';
import Dialog from '../Dialog/Dialog';
import {
  KeyPointsDialogContent,
  KeyPointsDialogContainer,
  KeyPointsDialogTitle,
  KeyPointsDialogTitleContainer,
  KeyPointsDialogButtonClose,
  SkillStandard,
  SkillStandardMissing,
  Separator,
} from './KeyPointsDialog.style';

const KeyPointsDialog = (): JSX.Element => {
  const { handleOnClose } = useHideDialog();
  const intl = useIntl();
  const { dialog } = useDialog();

  const { academy_slug, course_slug, skill_slug } = useMemo(() => {
    return {
      academy_slug: dialog.current_academy_slug,
      course_slug: dialog.current_course_slug,
      skill_slug: dialog.current_skill_slug,
    };
  }, [dialog]);

  const error = useMemo(() => {
    if (skill_slug === null || skill_slug === undefined || skill_slug === '') {
      return intl.formatMessage({ id: 'dialog.key-points.missing' });
    }
    return null;
  }, [intl, skill_slug]);

  const skill = useGetSkill(academy_slug ?? '', course_slug ?? '', skill_slug ?? '');

  const keyPoints = useMemo(() => {
    return skill?.standard;
  }, [skill]);

  return (
    <Dialog closeDialog={handleOnClose} hasBackgroundOverlay={true} alignY="flex-end">
      <Container>
        <Row>
          <KeyPointsDialogContainer>
            <KeyPointsDialogContent>
              <KeyPointsDialogTitleContainer>
                <KeyPointsDialogTitle>
                  {intl.formatMessage({ id: 'dialog.key-points.title' })}
                </KeyPointsDialogTitle>
                <KeyPointsDialogButtonClose
                  onClick={handleOnClose}
                  ariaLabel="close dialog button"
                  icon={<CloseIcon />}
                  buttonTheme="neutral"
                />
              </KeyPointsDialogTitleContainer>
              <Separator />
              {keyPoints !== undefined ? (
                <SkillStandard htmlContent={keyPoints} />
              ) : (
                <SkillStandardMissing>{error}</SkillStandardMissing>
              )}
            </KeyPointsDialogContent>
          </KeyPointsDialogContainer>
        </Row>
      </Container>
    </Dialog>
  );
};

export default KeyPointsDialog;
