import { useEffect } from 'react';
import { colorUsage } from 'colors';
import Zoom from 'smooth-zoom';

export const useZoomableImages = (...depsArray: any[]): void => {
  useEffect(() => {
    const zoom = Zoom('.image', {
      background: colorUsage.white.op_60,
    });
    return () => {
      zoom.detach('.image');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depsArray]);
};
