import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
let dialogRoot = document.getElementById('dialog-root');

const DialogPortal = ({ children }: { children?: JSX.Element }): JSX.Element => {
  const [portal] = useState(document.createElement('div'));
  useEffect(() => {
    if (!dialogRoot) {
      dialogRoot = document.createElement('div');
      dialogRoot.setAttribute('id', 'dialog-root');
      document.body.appendChild(dialogRoot);
    }
    if (dialogRoot !== null) dialogRoot.appendChild(portal);
    return () => {
      if (dialogRoot !== null) dialogRoot.removeChild(portal);
    };
  }, [portal]);
  return createPortal(children, portal);
};

export default DialogPortal;
