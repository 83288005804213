import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { CurrentDialog, DialogType, dialogTypes, hideDialog, showDialogAction } from './slice';
import { getCurrentDialog } from './selectors';
import { useNavigateToSkillPage } from 'services/routes/skill';
import { useIsInEmbedCycle } from 'services/routes';

export const useShowDialog = (): { showDialog: (type: DialogType) => void } => {
  const dispatch = useDispatch();
  const showDialog = useCallback(
    (type: DialogType) => {
      dispatch(showDialogAction({ type }));
    },
    [dispatch],
  );
  return {
    showDialog,
  };
};

export const useHideDialog = (): { handleOnClose: () => void } => {
  const dispatch = useDispatch();
  const handleOnClose = useCallback(() => {
    dispatch(hideDialog());
  }, [dispatch]);
  return {
    handleOnClose,
  };
};

export const useDialog = (): { dialog: CurrentDialog } => {
  return { dialog: useSelector(getCurrentDialog, shallowEqual) };
};

export const useShowStopTrainingDialog = (): {
  showStopTrainingDialog: (academy_slug: string, course_slug: string, skill_slug: string) => void;
} => {
  const dispatch = useDispatch();
  const { navigateToSkillPage } = useNavigateToSkillPage();
  const inEmbedCycle = useIsInEmbedCycle();

  const showStopTrainingDialog = useCallback(
    (academy_slug: string, course_slug: string, skill_slug: string) => {
      dispatch(
        showDialogAction({
          type: dialogTypes.stopTraining,
          confirmAction: () =>
            navigateToSkillPage(academy_slug, course_slug, skill_slug, inEmbedCycle),
        }),
      );
    },
    [dispatch, navigateToSkillPage, inEmbedCycle],
  );
  return {
    showStopTrainingDialog,
  };
};

export const useShowSkillKeyPointsDialog = (): {
  showSkillKeyPointsDialog: (academy_slug: string, course_slug: string, skill_slug: string) => void;
} => {
  const dispatch = useDispatch();

  const showSkillKeyPointsDialog = useCallback(
    (academy_slug: string, course_slug: string, skill_slug: string) => {
      dispatch(
        showDialogAction({
          type: dialogTypes.keyPoints,
          current_academy_slug: academy_slug,
          current_course_slug: course_slug,
          current_skill_slug: skill_slug,
        }),
      );
    },
    [dispatch],
  );
  return {
    showSkillKeyPointsDialog,
  };
};

export const useShowAccessDeniedDialog = (): {
  showAccessDeniedDialog: (user_email: string) => void;
} => {
  const dispatch = useDispatch();
  const showAccessDeniedDialog = useCallback(
    (user_email: string) => {
      dispatch(
        showDialogAction({
          type: dialogTypes.accessDenied,
          emailUserDenied: user_email,
        }),
      );
    },
    [dispatch],
  );
  return {
    showAccessDeniedDialog,
  };
};
