import { useDispatch } from 'react-redux';
import { addSkills, removeSkill } from './slice';
import { Skill } from 'redux/Skill';
import { useCallback, useEffect } from 'react';
import {
  createSkill,
  deleteSkill,
  fetchOneSkillByAcademyAndCourse,
  fetchSkillsByAcademyAndCourse,
  SkillToCreate,
  updateSkill,
} from 'services/networking/skills';
import {
  addOneSkillInAcademyStructure,
  addSkillsInAcademyStructureByAcademyAndCourse,
  getListOfUuidBySlugs,
  getOneUuidBySlugs,
} from 'redux/AcademyStructure';
import useSelector from 'redux/useSelector';
import { getSkill, getSkillsByUuid } from './selectors';
import { useNavigateToPageError } from 'services/routes';

export const useGetSkill = (
  academy_slug = '',
  course_slug = '',
  skill_slug = '',
): Skill | undefined => {
  const dispatch = useDispatch();

  const skillUuid = useSelector(getOneUuidBySlugs.skill(academy_slug, course_slug, skill_slug));
  const skill = useSelector(getSkill(skillUuid ?? ''));
  const { navigateToPageError } = useNavigateToPageError();

  useEffect(() => {
    const fetchSkill = async () => {
      if (skill_slug !== '' && academy_slug !== '' && course_slug !== '') {
        const { skill: fetchedSkill, error } = await fetchOneSkillByAcademyAndCourse(
          academy_slug,
          course_slug,
          skill_slug,
        );

        if (error !== undefined) {
          navigateToPageError(error);
        }

        if (fetchedSkill !== null) {
          dispatch(addSkills([fetchedSkill]));
          dispatch(addOneSkillInAcademyStructure(fetchedSkill));
        }
      }
    };
    if (skill === undefined) {
      fetchSkill();
    } else {
      if (skill.locked) {
        navigateToPageError(403);
      }
    }
  }, [skill, skill_slug, academy_slug, course_slug, dispatch, navigateToPageError]);
  return skill;
};

export const useGetSkills = (academy_slug: string, course_slug: string): Skill[] => {
  const dispatch = useDispatch();
  const skillsUuid = useSelector(getListOfUuidBySlugs.skills(academy_slug, course_slug));
  const skills = useSelector(getSkillsByUuid(skillsUuid));
  const { navigateToPageError } = useNavigateToPageError();

  useEffect(() => {
    const fetchSkills = async () => {
      const { skills: fetchedSkills, error } = await fetchSkillsByAcademyAndCourse(
        academy_slug,
        course_slug,
      );
      if (error !== undefined) {
        navigateToPageError(error);
      }

      if (fetchedSkills !== null && fetchedSkills.length > 0) {
        dispatch(
          addSkillsInAcademyStructureByAcademyAndCourse({
            academySlug: academy_slug,
            courseSlug: course_slug,
            skills: fetchedSkills,
          }),
        );
        dispatch(addSkills(fetchedSkills));
      }
    };

    fetchSkills();
  }, [course_slug, academy_slug, dispatch, navigateToPageError]);

  return skills;
};

export const useGetSkillUuid = (
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): string | null => {
  const dispatch = useDispatch();
  const skillUuid = useSelector(getOneUuidBySlugs.skill(academy_slug, course_slug, skill_slug));
  const { navigateToPageError } = useNavigateToPageError();

  useEffect(() => {
    const fetchSkill = async () => {
      if (skill_slug !== '' && skill_slug !== null) {
        const { skill: skillFetched, error } = await fetchOneSkillByAcademyAndCourse(
          academy_slug,
          course_slug,
          skill_slug,
        );
        if (error !== undefined) {
          navigateToPageError(error);
        }
        if (skillFetched !== null) {
          dispatch(addSkills([skillFetched]));
          dispatch(addOneSkillInAcademyStructure(skillFetched));
        }
      }
    };
    if (skillUuid === null) fetchSkill();
  }, [dispatch, academy_slug, course_slug, skill_slug, skillUuid, navigateToPageError]);

  return skillUuid;
};

export const useUpdateSkill = (): {
  updateSkill: (skillToUpdate: FormData) => Promise<void>;
} => {
  const dispatch = useDispatch();
  return {
    updateSkill: useCallback(
      async (skillToUpdate: FormData) => {
        const promise = new Promise<void>((resolve, reject) => {
          updateSkill(skillToUpdate).then((updatedSkill) => {
            if (updatedSkill !== null) {
              dispatch(addSkills([updatedSkill]));
              resolve();
            } else {
              reject();
            }
          });
        });
        return promise;
      },
      [dispatch],
    ),
  };
};

export const useCreateSkill = (): {
  createSkill: (skillToCreate: SkillToCreate) => Promise<void>;
} => {
  const dispatch = useDispatch();
  return {
    createSkill: useCallback(
      async (skillToCreate: SkillToCreate) => {
        const promise = new Promise<void>((resolve, reject) => {
          createSkill(skillToCreate).then((createdSkill) => {
            if (createdSkill !== null) {
              dispatch(addSkills([createdSkill]));
              resolve();
            } else {
              reject();
            }
          });
        });
        return promise;
      },
      [dispatch],
    ),
  };
};

export const useDeleteSkill = (
  skill: Skill,
): {
  deleteSkill: () => Promise<void>;
} => {
  const dispatch = useDispatch();
  return {
    deleteSkill: useCallback(async () => {
      const promise = new Promise<void>((resolve, reject) => {
        deleteSkill(skill.academy_slug, skill.course_slug, skill.slug).then((response) => {
          if (response === 'OK') {
            dispatch(removeSkill(skill.uuid));
            resolve();
          } else {
            reject();
          }
        });
      });
      return promise;
    }, [dispatch, skill.academy_slug, skill.course_slug, skill.slug, skill.uuid]),
  };
};
