import amplitude, { AmplitudeClient } from 'amplitude-js';
import { Focus } from 'redux/Focus/types';

const EVENT_NAMES = {
  pageViewed: 'Page viewed',
  userSignIn: 'User sign in',
  userSignOut: 'User sign out',
  startSimulatorButtonClicked: 'Start simulator button clicked',
  startFocusButtonClicked: 'Start focus button clicked',
  focusFinished: 'Focus finished',
  focusStarted: 'Focus started',
  simulatorFinished: 'Simulator finished',
};

export interface Event<Property> {
  name: string;
  properties?: Property;
}

type UserPropertyType = boolean | number | string | unknown[] | Record<string, unknown>;
export interface UserProperty {
  key: string;
  value: UserPropertyType;
}

export interface Page {
  name: string;
  pathname: string;
}

export type SignInMethod = 'google';

export interface SkillSlugIdentifiers {
  academySlug: string;
  courseSlug: string;
  skillSlug: string;
}

export interface AnalyticsClient {
  onEvent: (event: Event<unknown>) => void;
  onPageViewed: (page: Page) => void;
  onSignIn: (signinMethod?: SignInMethod) => void;
  onSignOut: () => void;
  onStartSimulatorButtonClicked: (skillSlugIdentifiers: SkillSlugIdentifiers) => void;
  onStartFocusButtonClicked: (focus: Focus, focusRank: number) => void;
  onFinishSimulator: (skillSlugIdentifiers: SkillSlugIdentifiers) => void;
  onStartFocus: (focus: Focus, focusRank: number) => void;
  onFinishFocus: (focus: Focus, focusRank: number) => void;
  setUserId: (userId: string | null) => void;
  setUserProperty: (property: UserProperty) => void;
  setUserProperties: (properties: Record<string, unknown>) => void;
}

class AmplitudeAnalyticsClient implements AnalyticsClient {
  private client: AmplitudeClient;
  constructor() {
    this.client = amplitude.getInstance();
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    this.client.init(apiKey ?? '');
  }
  public onEvent(event: Event<unknown>): void {
    this.client.logEvent(event.name, event.properties);
  }

  public onPageViewed(page: Page): void {
    this.client.logEvent(EVENT_NAMES.pageViewed, page);
  }

  public onSignIn(signinMethod = 'google'): void {
    this.client.logEvent(EVENT_NAMES.userSignIn, signinMethod);
  }

  public onSignOut(): void {
    this.client.logEvent(EVENT_NAMES.userSignOut);
  }

  public onStartSimulatorButtonClicked(skillSlugIdentifiers: SkillSlugIdentifiers): void {
    this.client.logEvent(EVENT_NAMES.startSimulatorButtonClicked, {
      academy_slug: skillSlugIdentifiers.academySlug,
      course_slug: skillSlugIdentifiers.courseSlug,
      skill_slug: skillSlugIdentifiers.skillSlug,
    });
  }

  public onStartFocusButtonClicked(focus: Focus, focusRank: number): void {
    this.client.logEvent(EVENT_NAMES.startFocusButtonClicked, {
      academy_slug: focus.academy_slug,
      course_slug: focus.course_slug,
      skill_slug: focus.skill_slug,
      focus_uuid: focus.uuid,
      focus_name: focus.name,
      focus_rank: focusRank,
    });
  }

  public onFinishSimulator(skillSlugIdentifiers: SkillSlugIdentifiers): void {
    this.client.logEvent(EVENT_NAMES.simulatorFinished, {
      academy_slug: skillSlugIdentifiers.academySlug,
      course_slug: skillSlugIdentifiers.courseSlug,
      skill_slug: skillSlugIdentifiers.skillSlug,
    });
  }

  public onStartFocus(focus: Focus, focusRank: number): void {
    this.client.logEvent(EVENT_NAMES.focusStarted, {
      academy_slug: focus.academy_slug,
      course_slug: focus.course_slug,
      skill_slug: focus.skill_slug,
      focus_uuid: focus.uuid,
      focus_name: focus.name,
      focus_rank: focusRank,
    });
  }

  public onFinishFocus(focus: Focus, focusRank: number): void {
    this.client.logEvent(EVENT_NAMES.focusFinished, {
      academy_slug: focus.academy_slug,
      course_slug: focus.course_slug,
      skill_slug: focus.skill_slug,
      focus_uuid: focus.uuid,
      focus_name: focus.name,
      focus_rank: focusRank,
    });
  }

  public setUserId(userId: string | null): void {
    this.client.setUserId(userId);
  }
  public setUserProperty(property: UserProperty): void {
    const identify = new amplitude.Identify().set(property.key, property.value);
    this.client.identify(identify);
  }
  public setUserProperties(properties: Record<string, unknown>): void {
    this.client.setUserProperties(properties);
  }
}

const analyticsClient: AnalyticsClient = new AmplitudeAnalyticsClient();

export default analyticsClient;
