import { useDispatch, useSelector } from 'react-redux';
import { academyFetched, Academy, academiesFetched } from './slice';
import { getAcademies, getAcademy } from './selectors';
import { useCallback, useEffect, useState } from 'react';
import { fetchAcademies, fetchAcademy } from 'request-utils';
import { updateAcademy } from 'services/networking/academies';

export const useGetAcademy = (
  academy_slug: string | null,
): { academy: Academy | undefined; isAcademyLoading: boolean } => {
  const dispatch = useDispatch();
  const academy = useSelector(getAcademy(academy_slug ?? ''));
  const [isAcademyLoading, setIsAcademyLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAcademy = async () => {
      if (academy_slug !== '' && academy_slug !== null) {
        const newAcademy = await fetchAcademy(academy_slug);
        if (newAcademy !== undefined && newAcademy !== null) {
          dispatch(academyFetched(newAcademy));
        }
        setIsAcademyLoading(false);
      }
    };
    if (academy === undefined) {
      getAcademy();
    } else {
      setIsAcademyLoading(false);
    }
  }, [academy, academy_slug, dispatch]);
  return { isAcademyLoading, academy };
};

export const useGetAcademies = (): { academies: Academy[]; areAcademiesLoading: boolean } => {
  const dispatch = useDispatch();
  const academies = useSelector(getAcademies);
  const [areAcademiesLoading, setAreAcademiesLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAcademies = async () => {
      const newAcademies = await fetchAcademies();
      if (newAcademies !== null && newAcademies.length > 0) {
        dispatch(academiesFetched(newAcademies));
      }
      setAreAcademiesLoading(false);
    };
    setAreAcademiesLoading(true);
    getAcademies();
  }, [dispatch]);
  return { areAcademiesLoading, academies };
};

export const useGetAcademyOrDefault = (academy_slug?: string): Academy | undefined => {
  const { academy, isAcademyLoading } = useGetAcademy(academy_slug ?? null);
  const dispatch = useDispatch();
  const [requestedAcademy, setRequestedAcademy] = useState(academy);

  useEffect(() => {
    async function getDefaultAcademyAsync() {
      const userAcademies = await fetchAcademies();
      dispatch(academiesFetched(userAcademies));
      setRequestedAcademy(userAcademies[0]);
    }
    if (academy === undefined && isAcademyLoading === false) {
      getDefaultAcademyAsync();
    } else {
      setRequestedAcademy(academy);
    }
  }, [academy, isAcademyLoading, academy_slug, dispatch]);

  return requestedAcademy;
};

export const useUpdateAcademy = (): {
  updateAcademy: (academyToUpdate: FormData) => Promise<void>;
} => {
  const dispatch = useDispatch();
  return {
    updateAcademy: useCallback(
      async (academyToUpdate: FormData) => {
        const promise = new Promise<void>((resolve, reject) => {
          updateAcademy(academyToUpdate).then((updatedAcademy) => {
            if (updatedAcademy !== null) {
              dispatch(academyFetched(updatedAcademy));
              resolve();
            } else {
              reject();
            }
          });
        });
        return promise;
      },
      [dispatch],
    ),
  };
};
