import ButtonClose from 'components/UI/Button/ButtonClose';
import styled from 'styled-components';
import { getSpacing, header } from 'stylesheet';
import { colorUsage } from 'colors';

export const CenteredDialogContainer = styled.div`
  width: ${getSpacing(60)};
  max-width: 650px;
  background: ${colorUsage.white.normal};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const CenteredDialogContent = styled.div`
  padding: ${getSpacing(6)} ${getSpacing(7)} ${getSpacing(3)} ${getSpacing(7)};
`;

export const CenteredDialogTitle = styled.h3`
  ${header.medium}
`;
export const CenteredDialogHeader = styled.div`
  padding: 20px ${getSpacing(9)};
  text-align: center;
  position: relative;
  border-bottom: 1px solid ${colorUsage.neutral.op_20};
`;

export const CenteredDialogCloseButton = styled(ButtonClose)`
  position: absolute;
  top: ${getSpacing(2)};
  right: ${getSpacing(2)};
`;
