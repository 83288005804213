import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { isUserLoggedIn } from 'redux/Login';
import { Pages } from 'pages';
import { setDeepLink } from 'services/localstorage';

const GuardedRoute = ({
  component,
  exact = false,
  path,
}: {
  component: ComponentType | undefined;
  exact?: boolean;
  path: string;
}): JSX.Element => {
  const isAuthenticated = useSelector(isUserLoggedIn);
  const location = useLocation();
  useEffect(() => {
    if (!isAuthenticated) {
      setDeepLink(location.pathname);
    }
  }, [isAuthenticated, location]);

  return isAuthenticated ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to={Pages.login.pathname} />
  );
};

export default GuardedRoute;
