import ButtonContained from 'components/UI/Button/ButtonContained';
import ButtonLink from 'components/UI/Button/ButtonLink/ButtonLink';
import styled from 'styled-components';
import { getSpacing, text } from 'stylesheet';

export const StopTrainingDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StopTrainingDialogAlert = styled.p`
  margin-bottom: ${getSpacing(6)};
  ${text.medium};
  text-align: center;
`;
export const StopTrainingDialogButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const StopTrainingDialogConfirmButton = styled(ButtonContained)``;
export const StopTrainingDialogCancelButton = styled(ButtonLink)``;
