import React from 'react';
import ButtonContainedStyled, {
  LeftIconContainer,
  RightIconContainer,
  ButtonLabel,
  ButtonContainedTheme,
} from './ButtonContained.style';

const ButtonContained = ({
  buttonLabel,
  onClick,
  leftIcon,
  rightIcon,
  buttonTheme = 'principal',
  disabled,
  className,
  type = 'button',
  ariaLabel = 'button full',
}: {
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLElement>) => void);
  buttonLabel: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  buttonTheme?: ButtonContainedTheme;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
}): JSX.Element => {
  return (
    <ButtonContainedStyled
      onClick={onClick}
      buttonTheme={buttonTheme}
      disabled={disabled}
      className={className}
      type={type}
      aria-label={ariaLabel}
    >
      {leftIcon !== undefined && <LeftIconContainer>{leftIcon}</LeftIconContainer>}
      <ButtonLabel>{buttonLabel}</ButtonLabel>
      {rightIcon !== undefined && <RightIconContainer>{rightIcon}</RightIconContainer>}
    </ButtonContainedStyled>
  );
};
export default ButtonContained;
