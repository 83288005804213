const colorPalette = {
  white: '#FFFFFF',
  white_20: 'rgba(255, 255, 255, 0.2)',
  white_40: 'rgba(255, 255, 255, 0.4)',
  white_60: 'rgba(255, 255, 255, 0.6)',
  white_80: 'rgba(255, 255, 255, 0.8)',
  principal_l: '#EBF0FB',
  principal: '#0017E5',
  principal_d: '#00008F',
  accent_l: '#FEF9F3',
  accent: '#EAB0A4',
  accent_d: '#6C2719',
  accent_20: 'rgba(234, 176, 164, 0.2);',
  accent_d_20: 'rgba(108, 39, 25, 0.2)',
  accent_d_40: 'rgba(108, 39, 25, 0.4)',
  accent_d_60: 'rgba(108, 39, 25, 0.6)',
  accent_40: 'rgba(234, 176, 164, 0.4)',
  accent_60: 'rgba(234, 176, 164, 0.6)',
  accent_80: 'rgba(234, 176, 164, 0.8)',
  neutral_l: '#F3F4F6',
  neutral: '#11294D',
  neutral_d: '#071222',
  neutral_10: 'rgba(17, 41, 77, 0.1)',
  neutral_20: 'rgba(17, 41, 77, 0.2)',
  neutral_40: 'rgba(17, 41, 77, 0.4)',
  neutral_60: 'rgba(17, 41, 77, 0.6)',
  neutral_80: 'rgba(17, 41, 77, 0.8)',
  success_l: '#EBF9F4',
  success: '#00A36D',
  success_d: '#006C67',
  danger_l: '#FBE8E8',
  danger: '#DF4242',
  danger_d: '#8F0000',
  warning_l: '#FDF4E8',
  warning: '#E69317',
  warning_d: '#8F6700',
};

export const colorUsage = {
  alert: {
    success_light: colorPalette.success_l,
    success: colorPalette.success,
    success_dark: colorPalette.success_d,
    warning_light: colorPalette.warning_l,
    warning: colorPalette.warning,
    warning_dark: colorPalette.warning_d,
    danger_light: colorPalette.danger_l,
    danger: colorPalette.danger,
    danger_dark: colorPalette.danger_d,
  },
  principal: {
    light: colorPalette.principal_l,
    normal: colorPalette.principal,
    dark: colorPalette.principal_d,
  },
  accent: {
    light: colorPalette.accent_l,
    normal: colorPalette.accent,
    dark: colorPalette.accent_d,
    op_20: colorPalette.accent_20,
    op_40: colorPalette.accent_40,
    op_60: colorPalette.accent_60,
    op_80: colorPalette.accent_80,
    d_op_20: colorPalette.accent_d_20,
    d_op_40: colorPalette.accent_d_40,
    d_op_60: colorPalette.accent_d_60,
  },
  neutral: {
    light: colorPalette.neutral_l,
    normal: colorPalette.neutral,
    dark: colorPalette.neutral_d,
    op_10: colorPalette.neutral_10,
    op_20: colorPalette.neutral_20,
    op_40: colorPalette.neutral_40,
    op_60: colorPalette.neutral_60,
    op_80: colorPalette.neutral_80,
  },
  white: {
    normal: colorPalette.white,
    op_20: colorPalette.white_20,
    op_40: colorPalette.white_40,
    op_60: colorPalette.white_60,
    op_80: colorPalette.white_80,
  },
};
