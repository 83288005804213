import { useCallback } from 'react';
import { useHistory, generatePath } from 'react-router';
import { AcademyPages, Pages } from 'pages';
import { navigateIfPossible, basePath } from './index';

export const useNavigateToSkillPage = (
  isDesigner = false,
): {
  navigateToSkillPage: (
    academy_slug?: string,
    course_slug?: string,
    skill_slug?: string,
    inEmbedCycle?: boolean,
  ) => void;
} => {
  const history = useHistory();
  const navigateToSkillPage = useCallback(
    (academy_slug?: string, course_slug?: string, skill_slug?: string, inEmbedCycle?: boolean) => {
      navigateIfPossible(
        [academy_slug, course_slug, skill_slug],
        () =>
          history.push(
            generatePath(
              basePath(isDesigner) +
                '/' +
                (inEmbedCycle === true
                  ? AcademyPages.embedSkillTraining.pathname
                  : AcademyPages.skill.pathname),
              {
                academy_slug,
                course_slug,
                skill_slug,
              },
            ),
          ),
        () => history.push(Pages.notFound.pathname),
      );
    },
    [history, isDesigner],
  );
  return { navigateToSkillPage };
};

export const useNavigateToDesignerSkillPage = (): {
  navigateToDesignerSkillPage: (
    academy_slug?: string,
    course_slug?: string,
    skill_slug?: string,
  ) => void;
} => {
  const { navigateToSkillPage } = useNavigateToSkillPage(true);
  return { navigateToDesignerSkillPage: navigateToSkillPage };
};

export const useSkillPageRoute = (
  isDesigner = false,
): {
  skillPageRoute: (academy_slug: string, course_slug: string, skill_slug: string) => string;
} => {
  const skillPageRoute = useCallback(
    (academy_slug: string, course_slug: string, skill_slug: string) =>
      generatePath(basePath(isDesigner) + '/' + AcademyPages.skill.pathname, {
        academy_slug,
        course_slug,
        skill_slug,
      }),
    [isDesigner],
  );
  return { skillPageRoute };
};
