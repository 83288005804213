import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  max-width: ${getSpacing(180)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ fullHeight = false }) => fullHeight && `min-height: 100vh;`}
`;

export const SmallContainer = styled(Container)`
  max-width: ${getSpacing(118)};
`;
