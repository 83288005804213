import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { colorUsage } from 'colors';
import { fontFamily, getSpacing } from 'stylesheet';

export const TooltipStyle = {
  // !important needed to override ReactTooltip style
  StyledReactTooltip: styled(ReactTooltip)`
    /* stylelint-disable declaration-no-important */
    background-color: ${colorUsage.neutral.dark} !important;
    border-radius: ${getSpacing(1)} !important;
    padding: ${getSpacing(1)} !important;
    min-height: ${getSpacing(5)} !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: left !important;
    letter-spacing: 0 !important;
    font-family: ${fontFamily.main} !important;
    /* stylelint-enable declaration-no-important */
  `,
};
