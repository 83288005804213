import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Pages } from 'pages';

const Auth0ProviderWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  return (
    <Auth0Provider
      domain={domain ?? ''}
      clientId={clientId ?? ''}
      authorizationParams={{
        redirectUri: window.location.origin + Pages.loginCheck.pathname,
        scope: 'profile email read:current_user',
        prompt: 'select_account',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
