import React from 'react';
import { ButtonLinkContainer, Label } from './ButtonLink.style';

const ButtonLink = ({
  buttonContent,
  onClick,
  type = 'button',
  ariaLabel = 'text button',
}: {
  buttonContent: string | JSX.Element;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
}): JSX.Element => {
  return (
    <ButtonLinkContainer aria-label={ariaLabel} onClick={onClick} type={type}>
      <Label>{buttonContent}</Label>
    </ButtonLinkContainer>
  );
};

export default ButtonLink;
