import React from 'react';
import { ButtonIconTheme, IconContainer } from './ButtonIcon.style';
import { Link } from 'react-router-dom';

const ButtonIcon = ({
  onClick,
  icon,
  buttonTheme = 'principal',
  className,
  ariaLabel = 'icon button',
  type = 'button',
  to,
}: {
  onClick?: () => void;
  icon: JSX.Element;
  buttonTheme?: ButtonIconTheme;
  className?: string;
  ariaLabel?: string;
  type?: 'button' | 'submit' | 'reset';
  to?: string;
}): JSX.Element => {
  if (to !== undefined) {
    return (
      <IconContainer
        as={Link}
        className={className}
        buttonTheme={buttonTheme}
        aria-label={ariaLabel}
        to={to}
      >
        {icon}
      </IconContainer>
    );
  }

  return (
    <IconContainer
      className={className}
      buttonTheme={buttonTheme}
      aria-label={ariaLabel}
      onClick={onClick}
      type={type}
    >
      {icon}
    </IconContainer>
  );
};
export default ButtonIcon;
