import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import GuardedRoute from 'components/Core/GuardedRoute/GuardedRoute';
import { LoaderCentered } from 'components/UI/Loader/Loader.style';
import { Pages } from 'pages';
import { usePageViewed } from 'services/analytics/usePageViewed';

const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Login'));
const LoginCheck = lazy(() => import('pages/LoginCheck'));
const NotFound = lazy(() => import('pages/NotFound'));
const Forbidden = lazy(() => import('pages/Forbidden'));
const AcademyRouter = lazy(() => import('pages/AcademyRouter'));

const Router = (): JSX.Element => {
  usePageViewed();
  return (
    <Suspense fallback={<LoaderCentered />}>
      <Switch>
        <Route exact path={Pages.login.pathname} component={Login} />
        <Route path={Pages.loginCheck.pathname} component={LoginCheck} />
        <GuardedRoute path={Pages.notFound.pathname} component={NotFound} />
        <GuardedRoute path={Pages.forbidden.pathname} component={Forbidden} />
        <GuardedRoute path={Pages.academyHome.pathname} component={AcademyRouter} />
        <GuardedRoute exact path={Pages.home.pathname} component={Home} />
      </Switch>
    </Suspense>
  );
};

export default Router;
