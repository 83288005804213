import { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

/* Language polyfills needed for IE11, Edge, Safari 12 & 13 support
https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import { useGetAcademy } from 'redux/Academy/hooks';
import { AcademyPages, DesignerPages, Pages } from 'pages';
import { matchPath, useLocation } from 'react-router';
import { LoaderCentered } from 'components/UI/Loader/Loader.style';

/* End of language polyfills */

const locales: Record<string, Record<string, string>> = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

interface Props {
  children: ReactNode;
}

interface matchPathType {
  isExact: boolean;
  params: {
    academy_slug: string;
  };
  path: string;
  url: string;
}

const matchPathAgainstAcademyPage = (path: string): matchPathType | null => {
  const { academyHome, ...remaningPages } = Pages;

  for (const [, page] of Object.entries(remaningPages)) {
    if (matchPath(path, { path: page.pathname, exact: true }) !== null) {
      return null;
    }
  }

  const matchedAgainstAcademy: matchPathType | null = matchPath(path, {
    path: Pages.academyHome.pathname,
    exact: true,
  });
  if (matchedAgainstAcademy !== null) {
    return matchedAgainstAcademy;
  } else {
    for (const [, page] of Object.entries(AcademyPages)) {
      const pagePath: string = Pages.academyHome.pathname + '/' + page.pathname;
      if (matchPath(path, { path: pagePath, exact: true }) !== null) {
        return matchPath(path, { path: Pages.academyHome.pathname });
      }
    }
    for (const [, page] of Object.entries(DesignerPages)) {
      const pagePath: string =
        Pages.academyHome.pathname + '/' + AcademyPages.designer.pathname + '/' + page.pathname;
      if (matchPath(path, { path: pagePath, exact: true }) !== null) {
        return matchPath(path, { path: Pages.academyHome.pathname });
      }
    }
  }
  return null;
};

const isAnAcademyPage = (path: string) => matchPathAgainstAcademyPage(path) !== null;

const DEFAULT_LANGUAGE_CODE = 'en';

const IntlProviderWrapper: React.FunctionComponent<Props> = ({ children }) => {
  const currentPath = useLocation().pathname;

  const matched: matchPathType | null = useMemo(
    () => matchPathAgainstAcademyPage(currentPath),
    [currentPath],
  );

  const academy_slug = useMemo(() => {
    if (matched !== null) {
      return matched.params.academy_slug;
    }
  }, [matched]);

  const { academy, isAcademyLoading } = useGetAcademy(academy_slug ?? '');

  const supportedLanguages = Object.keys(locales);

  const navigatorLanguageOrDefault = useMemo(() => {
    const navigatorLanguage = navigator.language.substring(0, 2);
    if (supportedLanguages.includes(navigatorLanguage)) {
      return navigatorLanguage;
    } else {
      return DEFAULT_LANGUAGE_CODE;
    }
  }, [supportedLanguages]);

  const locale: string = useMemo(() => {
    if (
      isAnAcademyPage(currentPath) &&
      !isAcademyLoading &&
      academy !== undefined &&
      supportedLanguages.includes(academy.default_language)
    ) {
      return academy.default_language;
    } else {
      return navigatorLanguageOrDefault;
    }
  }, [isAcademyLoading, academy, currentPath, navigatorLanguageOrDefault, supportedLanguages]);

  return (
    <React.Fragment>
      {isAnAcademyPage(currentPath) === true && isAcademyLoading === true ? (
        <LoaderCentered />
      ) : (
        <IntlProvider locale={locale} messages={locales[locale]}>
          {children}
        </IntlProvider>
      )}
    </React.Fragment>
  );
};

export default IntlProviderWrapper;
