import React from 'react';
import { useDialog } from 'redux/Dialog/hooks';
import { dialogTypes } from 'redux/Dialog/slice';
import { AccessDeniedDialog } from '../AccessDeniedDialog/AccessDeniedDialog';
import KeyPointsDialog from '../KeyPointsDialog/KeyPointsDialog';
import { StopTrainingDialog } from '../StopTrainingDialog/StopTrainingDialog';

export const DialogWrapper = (): JSX.Element => {
  const { dialog } = useDialog();
  return (
    <React.Fragment>
      {dialog.show === true && (
        <React.Fragment>
          {dialog.type === dialogTypes.stopTraining && <StopTrainingDialog />}
          {dialog.type === dialogTypes.keyPoints && <KeyPointsDialog />}
          {dialog.type === dialogTypes.accessDenied && <AccessDeniedDialog />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
