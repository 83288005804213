import { RootState } from 'redux/types';

const getCoursesUuidByAcademySlug =
  (academySlug: string) =>
  (store: RootState): string[] => {
    if (!store.academyStructure.academies_courses.hasOwnProperty(academySlug)) {
      return [];
    }
    const coursesUuidList = [];
    const courses = store.academyStructure.academies_courses[academySlug];
    for (const slug in courses) {
      coursesUuidList.push(courses[slug]);
    }
    return coursesUuidList;
  };

const getOneCourseUuidBySlugs =
  (academySlug: string, courseSlug: string) =>
  (store: RootState): string | null => {
    if (
      !store.academyStructure.academies_courses.hasOwnProperty(academySlug) ||
      !store.academyStructure.academies_courses[academySlug].hasOwnProperty(courseSlug)
    ) {
      return null;
    }
    return store.academyStructure.academies_courses[academySlug][courseSlug];
  };

const getSkillsUuidByAcademyAndCourse =
  (academySlug: string, courseSlug: string) =>
  (store: RootState): string[] => {
    if (
      !store.academyStructure.academies_courses_skills.hasOwnProperty(academySlug) ||
      !store.academyStructure.academies_courses_skills[academySlug].hasOwnProperty(courseSlug)
    ) {
      return [];
    }
    const skillsUuidList = [];
    const skills = store.academyStructure.academies_courses_skills[academySlug][courseSlug];
    for (const slug in skills) {
      skillsUuidList.push(skills[slug]);
    }
    return skillsUuidList;
  };

const getOneSkillUuidByAcademyAndCourse =
  (academySlug: string, courseSlug: string, skillSlug: string) =>
  (store: RootState): string | null => {
    if (
      !store.academyStructure.academies_courses_skills.hasOwnProperty(academySlug) ||
      !store.academyStructure.academies_courses_skills[academySlug].hasOwnProperty(courseSlug) ||
      !store.academyStructure.academies_courses_skills[academySlug][courseSlug].hasOwnProperty(
        skillSlug,
      )
    ) {
      return null;
    }
    return store.academyStructure.academies_courses_skills[academySlug][courseSlug][skillSlug];
  };

export const getOneUuidBySlugs = {
  skill: getOneSkillUuidByAcademyAndCourse,
  course: getOneCourseUuidBySlugs,
};

export const getListOfUuidBySlugs = {
  courses: getCoursesUuidByAcademySlug,
  skills: getSkillsUuidByAcademyAndCourse,
};
