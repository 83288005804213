import { Container } from 'components/UI/Layout';
import styled from 'styled-components';
import { colorUsage } from 'colors';

const getBackgroundColor = (hasBackgroundOverlay: boolean) => {
  return hasBackgroundOverlay ? colorUsage.neutral.op_80 : 'transparent';
};

export const DialogFullScreenWrapper = styled.div<{
  hasBackgroundOverlay: boolean;
  alignY?: 'center' | 'flex-end' | 'flex-start';
}>`
  width: 100vw;
  height: 100vh;
  background-color: ${({ hasBackgroundOverlay }) => getBackgroundColor(hasBackgroundOverlay)};
  display: flex;
  justify-content: center;
  align-items: ${({ alignY = 'center' }) => alignY};
`;

export const DialogPageContainer = styled(Container)`
  align-items: center;
`;
