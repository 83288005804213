import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Academy = {
  uuid: string;
  logo: string;
  cover: string | null;
  name: string;
  title: string;
  slug: string;
  default_language: string;
  is_designer: boolean;
  introduction: string | null;
  principal_light: string;
  principal_normal: string;
  principal_dark: string;
  are_groups_enabled: boolean;
};

export type AcademyState = {
  academies: Record<string, Academy>;
};

const initialState: Readonly<AcademyState> = { academies: {} };

const academySlice = createSlice({
  name: 'Academy',
  initialState,
  reducers: {
    academyFetched: (state, action: PayloadAction<Academy>) => {
      if (action.payload.slug) {
        state.academies[action.payload.slug] = action.payload;
      }
    },
    academiesFetched: (state, action: PayloadAction<Academy[]>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((academy) => {
          if (academy.slug) {
            state.academies[academy.slug] = academy;
          }
        });
      }
    },
  },
});

export const { academyFetched, academiesFetched } = academySlice.actions;
export default academySlice.reducer;
